@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&family=PT+Sans:ital,wght@0,400;0,700;1,400;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&family=PT+Sans:ital,wght@0,400;0,700;1,400;1,700&family=Red+Hat+Display:ital,wght@0,300..900;1,300..900&display=swap');
 


.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}


.poppins-thin {
  font-family: "Poppins", sans-serif;
  font-weight: 100;
  font-style: normal;
}

.poppins-extralight {
  font-family: "Poppins", sans-serif;
  font-weight: 200;
  font-style: normal;
}

.poppins-light {
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  font-style: normal;
}

.poppins-regular {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.poppins-medium {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-style: normal;
}

.poppins-semibold {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-style: normal;
}

.poppins-bold {
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  font-style: normal;
}

.poppins-extrabold {
  font-family: "Poppins", sans-serif;
  font-weight: 800;
  font-style: normal;
}

.poppins-black {
  font-family: "Poppins", sans-serif;
  font-weight: 900;
  font-style: normal;
}

.poppins-thin-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 100;
  font-style: italic;
}

.poppins-extralight-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 200;
  font-style: italic;
}

.poppins-light-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  font-style: italic;
}

.poppins-regular-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-style: italic;
}

.poppins-medium-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-style: italic;
}

.poppins-semibold-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-style: italic;
}

.poppins-bold-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  font-style: italic;
}

.poppins-extrabold-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 800;
  font-style: italic;
}

.poppins-black-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 900;
  font-style: italic;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.custom-select-container {
  position: relative;
  display: inline-block;

}
/* styles.css */

.options {
  max-height: 200px; /* Set the maximum height of the dropdown */
  overflow-y: auto; /* Enable vertical scrolling if the options exceed the maximum height */
}


.custom-select {
  appearance: none;
  
 
   text-align: center;
  padding-right: 20px;
  /* Adjust padding to avoid overlap with the arrow */
  padding-left: 20px;
  font-size: 12px;
  padding-top: 4px;
  padding-bottom: 4px;
  /* Adjust padding to make space for the text */
  border-radius: 9999px;
  /* For fully rounded corners */
  /* Customize as needed */
  background-color: transparent;
  /* Background color will be set inline */
  color: inherit;
  /* Text color will be set inline */
}

.custom-select-arrow {
  position: absolute;
  top: 50%;
  /* Adjust as needed */
  transform: translateY(-50%);
  pointer-events: none;
  /* Ensure clicks pass through to the select */
  font-size: 5px;
  /* Adjust size as needed */
}
.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.MuiSelect-select {
  height: auto;
  min-height: auto;
  text-overflow: initial;
  white-space: initial;
  overflow: initial;
}

.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input:focus {
  border-radius: initial;
}

.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
  -moz-appearance: initial;
  -webkit-appearance: initial;
  -webkit-user-select: initial;
  -moz-user-select: initial;
  -ms-user-select: initial;
  user-select: initial;
  border-radius: initial;
  cursor: pointer;
  font: inherit;
  letter-spacing: inherit;
  color: currentColor;
  border: initial;
  box-sizing: content-box;
  background: none;
  margin: 0;
  -webkit-tap-highlight-color: transparent;
  display: block;
  min-width: initial;
  width: 100%;
  -webkit-animation-name: initial;
  animation-name: initial;
  -webkit-animation-duration: initial;
  animation-duration: initial;
}
.icon-wrapper {
  display: inline-block;
}

.icon-wrapper.green {
  color: green;
}
.svg-icon{
  width: 50px; /* Adjust as needed */
  height: 50px; /* Adjust as needed */
  fill: green; /* Set the desired color */
}
.smooth-scroll {
  @apply overflow-y-scroll transition-all duration-500;
  scroll-behavior: smooth;
}
.custom-scrollbar::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 4px;
}

.custom-scrollbar::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.1);
}
html {
  scroll-behavior: smooth;
}
.pt-sans-regular {
  font-family: "PT Sans", sans-serif;
  font-weight: 400;
  font-style: normal;
}
.pt-sans-semibold {
  font-family: "PT Sans", sans-serif;
  font-weight: 500;
  font-style: normal;
}

.pt-sans-bold {
  font-family: "PT Sans", sans-serif;
  font-weight: 700;
  font-style: normal;
}

.pt-sans-regular-italic {
  font-family: "PT Sans", sans-serif;
  font-weight: 400;
  font-style: italic;
}



